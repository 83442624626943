import React, { useState } from 'react';
import { defaultColors, styled } from "../../stitches.config";
import { bodyLarge } from "../commonStyles/Fonts";
import { withThemeContext } from "../components/StitchesThemeProvider";

import CommentIcon from "../assets/icons/socialbutton-comment.svg";
import CommentIconBlack from "../assets/icons/socialbutton-comment-black.svg";

import { ReactComponent as CommentIconNav } from "../assets/icons/nav/article-nav/comment.svg?react";


interface SocialButtonProps {
  number: number;
  showText?: boolean;
  isDarkMode?: boolean;
  isDarkModeNotPrimary?: boolean
  isNavArticle?: boolean;
}


const SocialButton: React.FC<SocialButtonProps> = ({
  number = 0,
  isDarkMode,
  isDarkModeNotPrimary,
  showText = false,
  isNavArticle = false,
  ...props
}) => {
  const type = "comment";
  const isNumberMoreThenZero = number > 0;

  return (
    <StyledSocialButton href="#comments">
      {isNavArticle ?
        <SingleIcon>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87021 0.666504C1.65314 0.666504 0.666504 1.65314 0.666504 2.87021V19.4998L5.82231 15.633C6.14555 15.3896 6.53967 15.2584 6.94428 15.2591H17.1295C18.3465 15.2591 19.3332 14.2725 19.3332 13.0554V2.87021C19.3332 1.65314 18.3465 0.666504 17.1295 0.666504H2.87021ZM2.37484 2.87021C2.37484 2.59662 2.59662 2.37484 2.87021 2.37484H17.1295C17.4031 2.37484 17.6248 2.59662 17.6248 2.87021V13.0554C17.6248 13.329 17.4031 13.5508 17.1295 13.5508H6.94541C6.17008 13.5497 5.41548 13.8013 4.79588 14.2674L4.79474 14.2682L2.37484 16.0832V2.87021Z" />
          </svg>
        </SingleIcon>
        :
        <>
          <StyledSocialButtonIcon
            type={type}
            variant={isDarkMode ? isDarkModeNotPrimary ? "darkNotPrimary" : "dark" : undefined}
            className="social-btn-icon"
          >
            <StyledSocialButtonNumber className={bodyLarge()}>
              <span className={isNumberMoreThenZero ? "icon-padding" : ""}>{isNumberMoreThenZero && number}</span>
            </StyledSocialButtonNumber>
          </StyledSocialButtonIcon>
          {showText === true && (
            <StyledSocialButtonText className={bodyLarge()}>
              <span className={"text"}>Skomentuj{isNumberMoreThenZero && ": "}</span>
              {isNumberMoreThenZero && number}
            </StyledSocialButtonText>
          )}
        </>
      }
    </StyledSocialButton>
  );
};

const SingleIcon = styled('div', {
  width: "20px",
  height: "20px",
  "svg": {
    "path": {
      fill: "$primary"
    },
  }
})


const StyledSocialButton = styled("a", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& p": {
    margin: 0,
    color: "$grayscale100",
  },
  "& .text": {
    display: "none",
    "@bp4": {
      display: "block",
    },
  },
});

const StyledSocialButtonText = styled("p", {
  display: "none",
  "@bp4": {
    display: "flex",
  },
});

const StyledSocialButtonNumber = styled('span', {
  color: "$grayscale0",
  '@bp4': {
    display: "none",
  },
  '.icon-padding': {
    paddingLeft: "24px",
  }
})


const StyledSocialButtonIcon = styled('span', {
  backgroundColor: "$grayscale100",
  width: "max-content",
  padding: "4px 12px 4px 10px",
  borderRadius: "50px",
  borderWidth: 0,
  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  minWidth: "32px",
  minHeight: "32px",
  transition: "0.2s",
  "&:hover": {
    backgroundColor: "$primary",
  },
  '@bp4': {
    padding: "0",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginRight: "10px",
  },
  variants: {
    type: {
      comment: {
        backgroundImage: `url(${CommentIcon})`,
      },
    },
    variant: {
      dark: {
        backgroundColor: '$primary',
      },
      darkNotPrimary: {
        backgroundColor: "$grayscale25",
        backgroundImage: `url(${CommentIconBlack})`,
        backgroundSize: "18px",
        backgroundPosition: "center",
      }
    },
  },
});

export default withThemeContext(SocialButton);
